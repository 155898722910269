import { useCallback, useEffect, useRef, useState } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { analyticHelper } from '../../helpers/analyticHelper';
import { promoCodeActions } from '../../redux/promoCode/promoCodeSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Button } from '../../components/common/Button';
import { Header } from '../../components/common/Header';
import { ArrowIconRounded } from '../../icons/arrow-icon-rounded';
import { InfoIcon } from '../../icons/info-icon';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import promoCode from './promoCode.json';
import { Routes } from '../../const';

import './promoCode.scss';

export const MyPromoCode = (props) => {
  const { t } = useInternationalization();
  const history = useHistory();
  const { pristine } = props;

  const dispatch = useDispatch();
  const formRef = useRef();

  const [submitResult, setSubmitResult] = useState('');

  const promoCodeForm = formValueSelector('promoCode');
  const getPromoCodeSelector = (state) => promoCodeForm(state, 'promoCode');
  const promoCode = useSelector(getPromoCodeSelector);
  const isOnline = window.navigator.onLine;

  const goBack = () => history.push(Routes.MainRoute);

  const { isPromoCodeInfoButtonEnabled } = useFeatureToggles();

  useEffect(() => {
    analyticHelper.balancePromocode();

    const code = new URLSearchParams(window.location.search).get('code');

    if (!code) {
      return;
    }

    props.initialize({ promoCode: code });
    history.replace({ search: '' });
    setTimeout(() => formRef.current.requestSubmit(), 500);
  }, []);

  const openNoInternetConnectionModal = useCallback(() => {
    dispatch(
      modalActions.openErrorModal({
        description: 'Internet connection is probably interrupted',
        btnText: 'OK',
        onClose: () => {},
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  }, [dispatch]);

  const handleForm = (e) => {
    e.preventDefault();
    if (promoCode && isOnline) {
      return new Promise((resolve, reject) => {
        dispatch(
          promoCodeActions.postPromoCode({
            code: promoCode,
            onSuccess: resolve,
            onError: reject,
          }),
        );
      })
        .then(() => {
          setSubmitResult('success');
        })
        .catch(() => {
          setSubmitResult('error');
        });
    } else if (!isOnline) {
      openNoInternetConnectionModal();
    }
  };

  const openPromoCodeInfoModal = () => {
    dispatch(
      modalActions.openPromptModal({
        title: t('There are lots of ways to be given promo codes:'),
        description: [
          "1. Through Chat for extra special activity (we'll let you know how)",
          '2. Through your BA for completing a task or for high engagement with Connex',
          '3. Through your BA or Chat to mark special events',
        ],
        btnText: t('OK'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const openErrorModal = useCallback(() => {
    dispatch(
      modalActions.openErrorModal({
        description: 'Your promo code is invalid. Please try again',
        btnText: 'Try again',
        onClose: () => {
          setSubmitResult('');
        },
        onButtonClick: () => {
          setSubmitResult('');
          dispatch(modalActions.closeModal());
          analyticHelper.promocodeTryAgain();
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (submitResult === 'error') {
      openErrorModal();
    }
  }, [openErrorModal, submitResult]);

  if (submitResult === 'success') {
    return (
      <>
        <Header />
        <div className={'promo-code-page__success'}>
          <div className={'promo-code-page__success-image'}>
            <LottieWrapper
              options={options}
              style={{
                height: '340px',
              }}
            />
          </div>
          <div>
            <div className={'promo-code-page__success-header'}>
              {t('Great! Your promo code')}
              <br /> {t('is activated')}
            </div>
            <div className={'promo-code-page__success-text'}>{t("You've received your bonus points.")}</div>
          </div>

          <Button
            onClick={() => {
              analyticHelper.promocodeBackToBalance();
              goBack();
            }}
            customClass={'promo-code-page__success-back-button'}
          >
            {t('Back to balance')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="promo-code-page">
      <form ref={formRef} onSubmit={handleForm} className="promo-code-page__form">
        <div>
          <Button onClick={goBack} customClass="promo-code-page__back-button" withoutTheme>
            <ArrowIconRounded className="promo-code-page__header-arrow" />
          </Button>
          <p className="promo-code-page__header">{t('Enter your promo code')}</p>
          <div className="promo-code-page__input-container">
            <Field component="input" name="promoCode" className="promo-code-page__input" placeholder={t('Code')} />
            {isPromoCodeInfoButtonEnabled && (
              <InfoIcon className="promo-code-page__info-icon" onClick={openPromoCodeInfoModal} />
            )}
          </div>
        </div>

        <div customClass="promo-code-page__actions">
          <Button
            withoutTheme
            disabled={pristine}
            customClass="promo-code-page__submit"
            onClick={analyticHelper.promocodeActivate}
          >
            {t('Activate')}
          </Button>
          <Button
            tupe="button"
            withoutTheme
            customClass="promo-code-page__submit themed-background-inverted"
            onClick={() => history.push(Routes.PromoCodeScan)}
          >
            {t('promo_code.scan_code_button')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: promoCode,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const PromoCode = reduxForm({
  form: 'promoCode',
})(MyPromoCode);
