import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';

import styles from './OrderDetails.module.scss';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { orderDetailsSchema } from '../../validation/OrderDetailsSchema';
import { OrderDetailsFormData } from '../../types/OrderDetails';
import { Header } from '../../components/common/Header/Header';
import { CommonSelect } from '../../components/common/Select';
import { CommonInput } from '../../components/common/Input';
import { Balance } from '../../components/common/Balance';
import { ORDER_DELIVERY_TIME_SLOTS } from '../../const';

interface DeliveryTimeOption {
  id: string;
  name: string;
  value: number[];
}

interface FieldType {
  name: keyof OrderDetailsFormData;
  type?: string;
  inputmode?: string;
}

const OrderDetails = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();

  const deliveryTimeOptions: DeliveryTimeOption[] = useMemo(() => {
    return ORDER_DELIVERY_TIME_SLOTS.map((slot, index) => ({
      name: t('bonus_shop.order_details.time_slot', { timeFrom: slot[0], timeTo: slot[1] }),
      id: String(index),
      value: slot,
    }));
  }, []);

  // @ts-ignore
  const { selectedProviderId, selectedColorId } = useSelector((state) => state?.bonusShop);

  const { control, handleSubmit } = useForm<OrderDetailsFormData>({
    // @ts-ignore
    resolver: yupResolver<OrderDetailsFormData>(orderDetailsSchema),
    defaultValues: {
      time: '',
      phone: '',
      zip: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
    },
  });

  const onSubmit = ({ time, ...rest }: OrderDetailsFormData) => {
    const delivery_time = deliveryTimeOptions.find(({ id }) => id === time)?.value;

    dispatch(
      bonusShopActions.makeOrder({
        productId: selectedProviderId,
        product_color_id: selectedColorId,
        order_details: { delivery_time, ...rest },
      }),
    );
  };

  const fields: FieldType[] = [
    { name: 'phone', type: 'tel', inputmode: 'tel' },
    { name: 'zip' },
    { name: 'city' },
    { name: 'street' },
    { name: 'house' },
    { name: 'apartment' },
  ];

  return (
    <div className={styles.root}>
      <Header hasBackButton>
        <p className="bonus-shop-category-page__header-text">{t('bonus_shop.order_details.title')}</p>
      </Header>
      <Balance fixed={false} activePage={null} />
      <form className="content themed-background-image" onSubmit={handleSubmit(onSubmit)}>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="time"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonSelect
              meta={{ touched: true, error: error && t(error?.message) }}
              input={{ value, onChange }}
              placeholder={t(`bonus_shop.order_details.form_fields.time`)}
              options={deliveryTimeOptions}
            />
          )}
        />
        {fields.map(({ name, type, inputmode }) => (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CommonInput
                meta={{ error: error && t(error?.message), touched: true }}
                input={{ value, type, inputmode, onChange }}
                placeholder={t(`bonus_shop.order_details.form_fields.${name}`)}
              />
            )}
          />
        ))}
        <button>{t('bonus_shop.order_details.form_submit')}</button>
      </form>
    </div>
  );
};

export default OrderDetails;
