import { useSelector, useDispatch } from 'react-redux';
import { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import isEmpty from 'is-empty-typed';

import { getUnverificationInfoForBelarus } from '../../redux/verification/verificationSelectors';
import { getPrizesSelector } from '../../redux/bonusShop/bonusShopSelectors';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import OrderListItem from './OrderListItem';
import { Order } from '../../types/Order';
import styles from './Prizes.module.scss';

export const Prizes = memo(() => {
  const { formatMessage } = useInternationalization();
  const prizesRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const orders = useSelector(getPrizesSelector) as Order[];
  const isUnverified = useSelector(getUnverificationInfoForBelarus);
  const location = useLocation();

  useEffect(() => {
    dispatch(bonusShopActions.getPrizes({}));
    analyticHelper.prizesOpen();
  }, [dispatch]);

  useEffect(() => {
    const swappableViews = document.getElementById('swipable-views');
    if (swappableViews && prizesRef.current && location.pathname === '/prizes') {
      amplitudeHelper.openPrizes();
      swappableViews.style.height = `${prizesRef.current?.clientHeight}px`;
    }
  }, [location.pathname]);

  if (isUnverified) {
    return (
      <div className={styles.empty}>
        <p className={styles.description}>{formatMessage('Prizes are unavailable')}</p>
      </div>
    );
  }

  if (isEmpty(orders)) {
    return <div className={styles.empty}>{formatMessage('List is empty')}</div>;
  }

  return (
    <div className={styles.prizeContainer} ref={prizesRef}>
      {orders.map((order) => (
        <OrderListItem order={order} />
      ))}
    </div>
  );
});
