import { object, string } from 'yup';

const requiredMessage = 'common.validation.required';

export const orderDetailsSchema = object().shape({
  time: string().required(requiredMessage),
  phone: string().required(requiredMessage),
  zip: string().required(requiredMessage),
  city: string().required(requiredMessage),
  street: string().required(requiredMessage),
  house: string().required(requiredMessage),
  apartment: string(),
});
