import yn from 'yn';

import { APP_TYPE, APP_TYPES } from '../const/appConstants';
import { IProperties, IAppTypeProperties, PropertiesEnum } from './config.types';
import PROPERTIES from './config.constants';
import { AUTH_METHODS, Routes } from '../const';

export const CONFIG_KEYS = PROPERTIES;

const defaultProperties: IProperties = {
  /* Features */
  BILINGUAL_ENABLED: false,
  BONUS_SHOP_ENABLED: true,
  BONUS_SHOP_PRIZES_TAB_ENABLED: false,
  BONUS_SHOP_VOUCHERS_TAB_ENABLED: false,
  CASHOUT_DEMO_ENABLED: false,
  CIGARETTES_STYLE_WARNING_ENABLED: false,
  DAILY_BONUS_ENABLED: false,
  GAME_RULES_LINK_ENABLED: false,
  IOS_ADD_TO_HOME_SCREEN_BANNER_ENABLED: true,
  MONTHLY_COMPLIANCE_CHECK_ENABLED: false,
  NON_BONUS_VIEW_ENABLED: true,
  PUSH_NOTIFICATIONS_ENABLED: true,
  SELF_REGISTRATION_ENABLED: false,
  SUPPORT_WITH_TICKETS_ENABLED: false,
  TEAM_CHALLENGES_ENABLED: true,
  THEME_GALLERY_ENABLED: false,
  PROFILE_QUIZ_STATS_ENABLED: false,
  EXTENDED_PROFILE_INFO_ENABLED: true,
  INVITE_CODE_ENABLED: true,
  QUIZ_CARD_VIEW_ENABLED: false,
  SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED: false,
  FACEBOOK_STYLED_NOTIFICATIONS_ENABLED: false,
  CHAT_WITHOUT_OPTIONS_ENABLED: false,
  VOUCHERS_SPLIT_BY_CATEGORY_ENABLED: false,
  FALLING_SNOWFLAKES_ENABLED: false,
  SHOW_REFFERAL_MODULE_ENABLED: false,
  INTERACTIVE_ARTICLE_ENABLED: false,
  REDUCE_IMAGE_SIZE_ENABLED: false,
  SHOW_ZERO_BONUS_BLOCK_ENABLED: true,
  QUIZ_MODE_SWITCHER_ENABLED: true,
  SHOW_FOOTER_DISCLAIMER: true,
  SHOW_POINTS_AFTER_FINISH_ADDITIONAL_QUIZ: false,
  SHOW_GOAL_PROGRESS_ENABLED: false,
  ENABLE_ORDER_DETAILS: false,
  ENABLE_ONBOARDING_SCREEN: false,
  ENABLE_PROMO_CODE_INFO_BUTTON: false,
  SHOW_PROFILE_CONFIG_ICON: true,
  SHOW_LOGOUT_DELETE_ACCOUNT_BLOCK: false,

  /* Misc */
  AUTH_METHOD: AUTH_METHODS.OTP,
  DEFAULT_APP_THEME_ID: '2',
  DEFAULT_ICONS_THEME_ID: '1',
  SOLO_QUIZ_GAME_TIME_IN_SECONDS: 60,
  REDIRECT_ROUTE_AFTER_PURCHASE: Routes.BonusShopRoute,
};

const appTypeProperties: IAppTypeProperties = {
  [APP_TYPES.TAIWAN]: {
    ...defaultProperties,

    DAILY_BONUS_ENABLED: true,
    NON_BONUS_VIEW_ENABLED: false,
    BONUS_SHOP_VOUCHERS_TAB_ENABLED: true,
    MONTHLY_COMPLIANCE_CHECK_ENABLED: true,
    PROFILE_QUIZ_STATS_ENABLED: true,
    EXTENDED_PROFILE_INFO_ENABLED: false,
    INVITE_CODE_ENABLED: false,
    QUIZ_CARD_VIEW_ENABLED: true,
    DEFAULT_APP_THEME_ID: '6',
    DEFAULT_ICONS_THEME_ID: '0',
    SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED: true,
    FACEBOOK_STYLED_NOTIFICATIONS_ENABLED: true,
    REDIRECT_ROUTE_AFTER_PURCHASE: Routes.MyVouchers,
    VOUCHERS_SPLIT_BY_CATEGORY_ENABLED: true,
    SHOW_REFFERAL_MODULE_ENABLED: true,
    REDUCE_IMAGE_SIZE_ENABLED: true,
  },

  [APP_TYPES.TABATERRA_AZ]: {
    ...defaultProperties,

    BONUS_SHOP_ENABLED: true,
    NON_BONUS_VIEW_ENABLED: false,
    CHAT_WITHOUT_OPTIONS_ENABLED: true,
    SUPPORT_WITH_TICKETS_ENABLED: true,
    INTERACTIVE_ARTICLE_ENABLED: true,
    FALLING_SNOWFLAKES_ENABLED: false,
    SHOW_ZERO_BONUS_BLOCK_ENABLED: false,
    SHOW_GOAL_PROGRESS_ENABLED: true,
    ENABLE_ONBOARDING_SCREEN: true,

    DEFAULT_APP_THEME_ID: '7',
    SOLO_QUIZ_GAME_TIME_IN_SECONDS: 180,
  },

  [APP_TYPES.MYBRAND_AZ]: {
    ...defaultProperties,
    ENABLE_ORDER_DETAILS: true,
    DEFAULT_APP_THEME_ID: '8',
    BONUS_SHOP_PRIZES_TAB_ENABLED: true,
    SELF_REGISTRATION_ENABLED: true,
    SHOW_FOOTER_DISCLAIMER: false,
    SHOW_PROFILE_CONFIG_ICON: false,
    SHOW_LOGOUT_DELETE_ACCOUNT_BLOCK: true,
    NON_BONUS_VIEW_ENABLED: false,
    SUPPORT_WITH_TICKETS_ENABLED: true,
    CHAT_WITHOUT_OPTIONS_ENABLED: true,
    AUTH_METHOD: AUTH_METHODS.PASSWORD,
  },

  [APP_TYPES.DEMO]: {
    ...defaultProperties,
    NON_BONUS_VIEW_ENABLED: false,
    BONUS_SHOP_VOUCHERS_TAB_ENABLED: true,
    MONTHLY_COMPLIANCE_CHECK_ENABLED: true,
    PROFILE_QUIZ_STATS_ENABLED: true,
    EXTENDED_PROFILE_INFO_ENABLED: false,
    INVITE_CODE_ENABLED: false,
    QUIZ_CARD_VIEW_ENABLED: true,
    DEFAULT_APP_THEME_ID: '6',
    DEFAULT_ICONS_THEME_ID: '0',
    SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED: true,
    FACEBOOK_STYLED_NOTIFICATIONS_ENABLED: true,
    REDIRECT_ROUTE_AFTER_PURCHASE: Routes.MyVouchers,
    VOUCHERS_SPLIT_BY_CATEGORY_ENABLED: true,
    SHOW_REFFERAL_MODULE_ENABLED: true,
    REDUCE_IMAGE_SIZE_ENABLED: true,
    SUPPORT_WITH_TICKETS_ENABLED: true,
    CHAT_WITHOUT_OPTIONS_ENABLED: true,
    CASHOUT_DEMO_ENABLED: true,
    QUIZ_MODE_SWITCHER_ENABLED: false,
    SHOW_FOOTER_DISCLAIMER: false,
    SHOW_ZERO_BONUS_BLOCK_ENABLED: false,
    SHOW_POINTS_AFTER_FINISH_ADDITIONAL_QUIZ: true,
  },
};

export const isFeatureEnabled = (property: PropertiesEnum) => {
  return getConfigFor(property, true);
};

export const getConfigFor = (property: PropertiesEnum, toBoolean?: boolean) => {
  const envVariable = process.env[`REACT_APP_${property}`];

  if (envVariable !== undefined) {
    return toBoolean ? yn(envVariable) : envVariable;
  }

  return appTypeProperties[APP_TYPE as string]
    ? appTypeProperties[APP_TYPE as string][property]
    : defaultProperties[property];
};
