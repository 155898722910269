import { CONFIG_KEYS, getConfigFor, isFeatureEnabled } from '../config';
import { AUTH_METHODS } from '../const';

const useFeatureToggles = () => {
  const isOrderDetailsEnabled = isFeatureEnabled(CONFIG_KEYS.ENABLE_ORDER_DETAILS);
  const isOnboardingScreenEnabled = isFeatureEnabled(CONFIG_KEYS.ENABLE_ONBOARDING_SCREEN);
  const isPromoCodeInfoButtonEnabled = isFeatureEnabled(CONFIG_KEYS.ENABLE_PROMO_CODE_INFO_BUTTON);
  const isOPTAuthEnabled = getConfigFor(CONFIG_KEYS.AUTH_METHOD) === AUTH_METHODS.OTP;
  const isPasswordAuthEnabled = getConfigFor(CONFIG_KEYS.AUTH_METHOD) === AUTH_METHODS.PASSWORD;
  const isAuthDisabled = getConfigFor(CONFIG_KEYS.AUTH_METHOD) === AUTH_METHODS.NONE;

  return {
    isOrderDetailsEnabled,
    isOnboardingScreenEnabled,
    isPromoCodeInfoButtonEnabled,
    isOPTAuthEnabled,
    isPasswordAuthEnabled,
    isAuthDisabled,
  };
};

export default useFeatureToggles;
