import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import styles from './Prizes.module.scss';
import { formatOrderDetailsAddress } from '../../helpers/bonusShopHelpers';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { DownloadIcon } from '../../icons/download-icon';
import { ORDERS_STATUSES } from '../../const';
import { formatDate } from '../../helpers';
import { Order } from '../../types/Order';

interface OrderListItemProps {
  order: Order;
}

const OrderListItem = ({ order }: OrderListItemProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const currency = '€';
  const coefficient = 100;
  const {
    id,
    categoryPhoto,
    providerName,
    categoryName,
    createdAt,
    price,
    nominal_price,
    pdfLink,
    orderDetails,
    status,
  } = order;

  const onDownloadClick = (id: number) => {
    window.open(pdfLink);
    dispatch(bonusShopActions.viewed({ reward_id: id }));
  };

  const statusCode = ORDERS_STATUSES.find(({ value }) => value === status)?.name;
  const orderStatus = statusCode ? formatMessage(`bonus_shop.order_status.${statusCode?.toLowerCase()}`) : null;

  return (
    <div key={id} className={classnames(styles.blockWrapper)}>
      <div className={styles.prize}>
        <img src={categoryPhoto} className={styles.photo} alt="photo" />
        <div className={styles.prizeInfo}>
          <span className={styles.title}>{providerName}</span>
          {orderStatus && <span className={styles.title}>{orderStatus}</span>}
          {orderDetails && (
            <>
              <span className={styles.price}>{formatOrderDetailsAddress(orderDetails)}</span>
              <span className={styles.price}>{orderDetails.phone}</span>
            </>
          )}
          <p className={styles.price}>
            {categoryName} {price ? ` • ${currency}` + (nominal_price || price / coefficient).toFixed(0) : ''}
          </p>
          <p className={styles.price}>{formatDate(new Date(createdAt), 'dd MMMM yyyy')}</p>
        </div>
      </div>
      {pdfLink && (
        <button type="submit" className={styles.downloadButton} onClick={() => onDownloadClick(id)}>
          <DownloadIcon />
        </button>
      )}
    </div>
  );
};

export default OrderListItem;
